import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Thanks extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="You Form has been Submitted" />
        <h1>Thank you!</h1>
        <p>Your form has been submitted and we will get back to you as soon
        as possible. Do know we're a small team so please allow a day or two.</p>
      </Layout>
    )
  }
}

export default Thanks

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
